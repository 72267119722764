<template>
    <div :class="is_show ?  'left-open-box left-box' :'left-close-box left-box'" >
        <div  class="left-box-bg">
            <div class="left-phone">{{info.web_site_tel }}</div>
            <div class="left-text">合作电话</div>
            <img class="left-code-bg left-code1" :src="info.web_official_code" />
            <img class=" left-tetxt-img1" style="height:50px"  src="../assets/yushen/hzzx.png" />
            <img class="left-code-bg left-code2" :src="info.code2" />
            <img  src="../assets/yushen/gzgzh.png" />
            <img style="width:80%;margin-top:10px" src="../assets/yushen/left-douyin.png" />
        </div>
        <div  :class="is_show ?  'left-close-icon left-icon' :'left-open-icon left-icon'"   @click="close">
            <img v-if="is_show" style="width:50px" src="../assets/yushen/left1.png" />
            <img v-else style="width:50px" src="../assets/yushen/left2.png" />
        </div>
    </div>
    
</template>

<script>
    export default {
        props:{
            info:{
              type:Object,
              default:{
                web_official_code:""
              }
            }
        },
        data() {
            return {
                is_show:false
            }
        },
        created(){
            console.log(this.info)
        },
        methods: {
            close(){
                this.is_show = !this.is_show
            }
        }
    }
</script>

<style scoped>
    .left-box{
        position: fixed;
        z-index: 9999999;
        top: 5px;
        left: 10px;
        transition: .3s all;
        width:139px;
        height:728px;
    }
    .left-open-box{
        left: 10px;
    }
    .left-close-box{
        left:-110px
    }
    .left-box-bg{
        background:url('../assets/yushen/left-nav.png');
        text-align: center;
        font-weight: bold;
        width:100%;
        height:100%;
    }
    .left-phone{
        padding-top: 40px;
        color: #fff;
    }
    .left-text{
        padding-top: 5px;
        color: #fff;
        font-size:27px
    }
    .left-code-bg{
        background-color:#fff;
        width:80%;
    }
    .left-code1{
        margin-top:15px
    }
    .left-tetxt-img1{
        margin-top:5px
    }
    .left-hezuo{
        color: #000;
        font-size:27px
    }
    .left-code2{
        margin-top:30px
    }
    .left-icon{
        position: fixed;
        top: 50%;
        transition: .3s all;
        cursor: pointer;
    }
    .left-close-icon{
        left: 135px;
    }
    .left-open-icon{
        left: 15px;
    }
</style>