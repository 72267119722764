<template>
   
          <div class="cy-class">创业有风险 投资需谨慎</div>
   
  </template>
  
  <script>
  export default {
    name: 'tiups',
    data() {
        return {
            menu:[]
        }
    },
    created() {
    },
    methods: {
    }
  }
  </script>
  
  <style scoped >
      .cy-class {
          background-color: #035331;
          padding: 20px 10px;
          text-align: center;
          color: #fff;
          font-weight: bold;
          writing-mode: vertical-rl;
          position: fixed;
          top: 10%;
          right:10px;
          z-index: 9999999;
          opacity: 0.6;
      }
  </style>
  